export const environment = {
  production: false,
  logrocket: true,
  logrocketAppID: 'd1y2gb/bdn-dev',
  embedded: false,
  sapIotBaseUrl: 'https://bdn-qa.cfapps.eu10.hana.ondemand.com',
  googleAnalyticsId: 'G-YRPWB5EZ4C',
  featureFlags: {
    cnSystemView: true
  },
  keycloak: {
    url: 'https://auth.dev.mybitzer.de/auth',
    realm: 'bdn',
    clientId: 'bdn-ui',
  },
  availableLanguages: [
    { code: 'en', label: 'English' },
    { code: 'de', label: 'Deutsch' },
    { code: 'et', label: 'Estonian' },
    { code: 'fr', label: 'French' },
    { code: 'it', label: 'Italian' },
    { code: 'ko', label: 'Korean' },
    { code: 'pt', label: 'Portuguese' },
    { code: 'ru', label: 'Russian' },
    { code: 'vi', label: 'Vietnamese' },
    { code: 'zh', label: 'Chinese' }
  ],
  tabOrder: {
    equipmentDetails: ["status", "operational/graph", "operational/report", "alarms", "module", "information", "documents", "spareparts"]
  },
  cnEnergyView: false
}
